<template>
    <el-dialog
        :before-close="closeDialog"
        :show-close="false"
        :visible.sync="dialogVisible"
        center
        width="7.9in">
        <span slot="title" class="title">打印预览</span>
        <div class="dialog-container">
            <div id="printTest">
                <div class="apply-title">会议结算单</div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">项目编号：</span>
                    <span class="approval-detail-content">{{ detail.project.pro_no.join(',') }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">所属学会：</span>
                    <span class="approval-detail-content">{{ detail.society }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{$getTitleByType('会议名称',detail.project.cid)}}：</span>
                    <span class="approval-detail-content">{{ detail.project.name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{$getTitleByType('会议日期',detail.project.cid)}}：</span>
                    <span class="approval-detail-content">{{ detail.time }}</span>
                </div>
                <div class="approval-detail-title">
                          <span class="approval-detail-tip">{{$getTitleByType('大会主席',detail.project.cid)}}：</span
                          ><span class="approval-detail-content">{{ detail.project.chairman }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">备注：</span>
                    <span class="approval-detail-content">{{ detail.remark }}</span>
                </div>
                <div class="approval-detail-container">
                    <div class="project-info">
                        <div class="settlement-tab">
                            <div class="detail-info">
                                <h3>会议结算单</h3>
                                <h4><span>一、收款明细</span>
                                </h4>
                                <p class="active">单位：万元 </p>
                            </div>
                            <el-table
                                :data="settlement1"
                                header-row-class-name="table-header"
                                style="width: 1000px">
                                <el-table-column
                                    align="center"
                                    label="序号"
                                    type="index"
                                    width="100"
                                >
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="赞助单位"
                                    prop="num1"
                                >
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="赞助金额"
                                    prop="num2"
                                >
                                    <template slot-scope="scope">
                                        <div class="table-item">
                                            {{ scope.row.num2 | tofixed }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="到账金额"
                                    prop="num3"
                                >
                                    <template slot-scope="scope">
                                        <div class="table-item">
                                            {{ scope.row.num3 | tofixed }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="服务费+税金"
                                    prop="num4"
                                >
                                    <template slot-scope="scope">
                                        <div class="table-item">
                                            {{ scope.row.num4 | tofixed }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="可用专项基金"
                                    prop="num5"
                                >
                                    <template slot-scope="scope">
                                        <div class="table-item">
                                            {{ scope.row.num5 | tofixed }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="备注"
                                    prop="amount_desc">
                                    <template slot-scope="scope">
                                        <div class="table-item">
                                            {{ scope.row.amount_desc || '--' }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    v-if="eid === 0"
                                    align="center"
                                    label="操作"
                                    width="200px"
                                >
                                    <template slot-scope="scope">
                                        <div class="table-operate">
                                            <el-button size="small" style="font-size: 14px;" type="text"
                                                       @click="editItem(scope.$index)">编辑
                                            </el-button>
                                            <el-button size="small" style="font-size: 14px; color: #e4393c;" type="text"
                                                       @click="closeItem(scope.$index,1)">删除
                                            </el-button>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-table
                                :data="total"
                                :show-header="false"
                                class="table-total"
                                style="width: 1000px;background: #f2f2f2;">
                                <el-table-column
                                    align="center"
                                    width="100"
                                >
                                    <template slot-scope="scope">
                                        <div class="table-item">
                                            {{ scope.row.name }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot-scope="scope">
                                        <div class="table-item">
                                            {{ scope.row.num1 }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot-scope="scope">
                                        <div class="table-item">
                                            {{ scope.row.num2 | tofixed }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot-scope="scope">
                                        <div class="table-item">
                                            {{ scope.row.num3 | tofixed }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot-scope="scope">
                                        <div class="table-item">
                                            {{ scope.row.num4 | tofixed }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot-scope="scope">
                                        <div class="table-item">
                                            {{ scope.row.num5 | tofixed }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot-scope="scope">
                                        <div class="table-item">
                                            {{ scope.row.num0 }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    v-if="eid === 0"
                                    label=" "
                                    width="200px"
                                >
                                </el-table-column>
                            </el-table>

                            <div class="detail-info">
                                <h4>二、付款明细
                                </h4>
                                <p class="active">单位：万元 </p>
                            </div>
                            <el-table
                                :data="settlement2"
                                header-row-class-name="table-header"
                                style="width: 1000px">
                                <el-table-column
                                    align="center"
                                    label="序号"
                                    type="index"
                                    width="100"
                                >
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="付款项目"
                                    prop="num1"
                                >
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="付款金额"
                                    prop="num2"
                                >
                                    <template slot-scope="scope">
                                        <div class="table-item">
                                            {{ scope.row.num2 | tofixed }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="收款单位"
                                    prop="num3"
                                >
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="专项基金余额"
                                    prop="num4"
                                >
                                    <template slot-scope="scope">
                                        <div class="table-item">
                                            {{ scope.row.num4 | tofixed }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    v-if="eid === 0"
                                    align="center"
                                    label="操作"
                                    width="200px"
                                >
                                    <template slot-scope="scope">
                                        <div class="table-operate">
                                            <el-button size="small" style="font-size: 14px;" type="text"
                                                       @click="editItem2(scope.$index)">编辑
                                            </el-button>
                                            <el-button size="small" style="font-size: 14px; color: #e4393c;" type="text"
                                                       @click="closeItem(scope.$index,2)">删除
                                            </el-button>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-table
                                :data="total2"
                                :show-header="false"
                                class="table-total"
                                style="width: 1000px;background: #f2f2f2;">
                                <el-table-column
                                    align="center"
                                    prop="name"
                                    width="100"

                                >
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    prop=""
                                >
                                </el-table-column>
                                <el-table-column
                                    align="center">
                                    <template slot-scope="scope">
                                        <div class="table-item">
                                            {{ scope.row.num1 | tofixed }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center">
                                    <template slot-scope="scope">
                                        <div class="table-item">
                                            {{ scope.row.num2 }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center">
                                    <template slot-scope="scope">
                                        <div class="table-item">
                                            {{ scope.row.num3 | tofixed }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    v-if="eid === 0"
                                    align="center"
                                    label=" "
                                    width="200px"
                                >
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div class="approval-detail-title">
                        <div class="approval-detail-content">
                            <el-image
                                :src="detail.sign"
                                style="width: 550px; height: auto"
                            ></el-image>
                        </div>
                    </div>
                    <div class="approval-list-container">
                        <div class="approval-list-tip">审批流程</div>

                        <div class="approval-list">
                            <div class="name-container active">
            <span>
              {{
                    detail.true_name | sub_name
                }}
              <i class="el-icon-success"></i>
            </span>
                                <div class="info-container">
                                    <div class="info-top">
                                        <div class="info-content">{{ detail.true_name }}</div>
                                        <div class="info-time">{{ detail.add_time | formatTime }}</div>
                                    </div>
                                    <div class="info-desc">发起申请</div>
                                </div>
                            </div>
                            <div class="name-container active">
            <span>
              签字
              <i
                  :class="detail.sign ? 'el-icon-success' : 'el-icon-remove'"
              ></i>
            </span>
                                <div class="info-container">
                                    <div class="info-top">
                                        <div class="info-content">
                                            确认人
                                        </div>
                                        <div v-if="detail.allSp[0].sp_time" class="info-time">
                                            {{ detail.allSp[0].sp_time | formatTime }}
                                        </div>
                                    </div>
                                    <div class="info-desc">
                                        {{
                                            detail.sign
                                                ? "已签字"
                                                : "签字中"
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="detail.state != 0 && detail.sign"
                                class="name-container active"
                            >
            <span>
              {{
                    detail.true_name | sub_name
                }}
              <i
                  :class="
                  detail.jiesuan_jiaojie && detail.jiesuan_jiaojie.length > 0
                    ? 'el-icon-success'
                    : 'el-icon-remove'
                "
              ></i>
            </span>
                                <div class="info-container">
                                    <div class="info-top">
                                        <div class="info-content">{{ detail.true_name }}</div>
                                        <div v-if="detail.allSp[1].sp_time" class="info-time">
                                            {{ detail.allSp[1].sp_time | formatTime }}
                                        </div>
                                    </div>
                                    <div v-if="detail.jiesuan_jiaojie && detail.jiesuan_jiaojie.length > 0" class="info-desc">
                                        已提交结算单
                                    </div>
                                    <div v-else class="info-desc">
                                        <div>待确认</div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="
              detail.state != 0 &&
              detail.state != 2 &&
              detail.jiesuan_jiaojie && detail.jiesuan_jiaojie.length > 0
            "
                            >
                                <div v-for="(item,index) in detail.shenpi"
                                     :key="item.id">
                                    <div
                                        v-if="index === 0 ? true : detail.shenpi[index-1].state === 1"
                                        class="name-container active"
                                    >
              <span
              >{{
                      item.true_name | sub_name
                  }}
                <i
                    :class="
                    item.state === 0 || item.state === 3
                      ? 'el-icon-remove'
                      : item.state === 1
                      ? 'el-icon-success'
                      : 'el-icon-error'
                  "
                ></i>
              </span>
                                        <div class="info-container">
                                            <div class="info-top">
                                                <div class="info-content">
                                                    {{ item.true_name
                                                    }}<i v-if="item.state != 0"
                                                >({{ item.state | sp_state }})</i
                                                >
                                                </div>
                                                <div v-if="item.sp_time" class="info-time">
                                                    {{ item.sp_time | formatTime }}
                                                </div>
                                            </div>
                                            <div v-if="item.state === 0" class="info-desc">
                                                {{ iself(item) ? "我（" : "" }}{{ item.state | sp_state
                                                }}{{ iself(item) ? "）" : "" }}
                                            </div>
                                            <div v-if="item.state !== 0" class="info-desc">
                                                {{ item.sp_remark }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="detail.shenpi[detail.shenpi.length -1].state === 1"
                                class="name-container"
                            >
            <span>
              {{
                    detail.true_name | sub_name
                }}
              <i
                  :class="
                  detail.confirm == 1 ? 'el-icon-success' : 'el-icon-remove'
                "
              ></i>
            </span>
                                <div class="info-container">
                                    <div class="info-top">
                                        <div class="info-content">
                                            {{ detail.true_name }}
                                        </div>
                                        <div v-if="detail.allSp[detail.allSp.length - 1].sp_time" class="info-time">
                                            {{ detail.allSp[detail.allSp.length - 1].sp_time | formatTime }}
                                        </div>
                                    </div>
                                    <div class="info-desc">
                                        {{ detail.confirm == 1 ? "确认知晓" : "确认中" }}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取消</el-button>
            <el-button v-print="'#printTest'" class="printing" type="primary">打印</el-button>
      </span>
    </el-dialog>
</template>

<script>
import {sub_name, ZHDate} from "../../../tools";

export default {
    name: "other-fpr-print",
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        detail: { //详情
            type: Object,
        },
        settlement1:{ //详情
            type: Array,
        },
        total: { //详情
            type: Array,
        },
        settlement2:{ //详情
            type: Array,
        },
        total2: { //详情
            type: Array,
        },
    },
    methods: {
        iself: function (item) {
            return item.user_id === this.$store.state.user.mid;
        },
        closeDialog() {
            this.$emit('watchState', false)
        }
    },
    filters: {
        tofixed(val) {
            return parseFloat((val * 1).toFixed(6))
        },
        fukuan_jiaojie(type) {
            let arr = ["支出凭证", "下游专票", "下游协议", "项目明细", "付款确认单"];
            let str = "";
            type.map((item) => {
                str = str + " " + arr[Number(item) - 1];
            });
            return str;
        },
        jiesuan_jiaojie(type) {
            let arr = ["会议结算单", "电子结算文件"];
            let str = "";
            type.map((item) => {
                str = str + " " + arr[Number(item) - 1];
            });
            return str;
        },
        state(type) {
            switch (Number(type)) {
                case 0:
                    return "等待签字确认";
                case 1:
                    return "等待发起人确认";
                case 2:
                    return "已签字，等待提交结算交接文件";
                case 3:
                    return "已驳回";
                case 4:
                    return "审批中";
                case 5:
                    return "已撤销";
                case 6:
                    return "已完成";
            }
        },
        sp_state(type) {
            switch (Number(type)) {
                case 0:
                    return "审批中";
                case 3:
                    return "未审批";
                case 1:
                    return "已通过";
                default:
                    return "已驳回";
            }
        },
        formatTime(time) {
            return ZHDate(new Date(time * 1000), 6)
        },
        sub_name(str) {
            return sub_name(str)
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 16px;
    color: #333333;
    text-align: center;
    font-weight: bold;
}

.dialog-container {
    height: 600px;
    overflow-y: scroll;
    border: 1px solid #e1e1e1;

    &::-webkit-scrollbar {
        display: none;
    }
}

#printTest {
    background: #FFFFFF;
    padding: 20px;
    border: 1px solid #e1e1e1;
}

.apply-title {
    font-size: 22px;
    color: #333333;
    text-align: center;
    font-weight: bold;
    margin: 20px 0 50px;
}

.project-info {
    width: 100%;
    background-color: #ffffff;
    border-radius: 6px;
    min-height: 750px;

    .project-condition {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 24px 28px 16px;
        border-bottom: 2px solid #f5f5f5;

        .title-tab {
            .active {
                background: #3b77e7;
                color: #fff;
            }

            .settlement {
                margin-left: 10px;
            }
        }

        .el-select {
            margin-right: 20px;
        }

        .el-input {
            margin: 0 20px;
            width: 268px;
        }
    }

    .settlement-tab {
        .detail-info {
            h3 {
                text-align: center;
                font-size: 24px;
                line-height: 36px;
            }

            h4 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;
                font-size: 18px;
                margin-bottom: 15px;
            }

            p {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 6px;

                &.active {
                    justify-content: flex-start;
                }
            }
        }
    }

    .save-btn {
        display: block;
        width: 120px;
        margin: 30px auto;
    }
}

.approval-detail-title {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    color: #999999;
    width: 100%;
    padding: 10px 0;

    .approval-detail-tip {
        flex: 1;
        flex-shrink: 0;

        &.red {
            color: red;
        }
    }

    .approval-detail-tip-length {
        flex-shrink: 0;

        &.red {
            color: red;
        }
    }

    .approval-detail-content {
        flex: 4;
        flex-shrink: 1;
        color: #333333;

        &.red {
            color: red;
        }
    }
}
.approval-list-container {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;

    .approval-list {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        .name-container {
            position: relative;
            display: flex;
            padding-bottom: 50px;
            flex-direction: row;

            &.active {
                &:before {
                    position: absolute;
                    background: #d3d4d6;
                    width: 1px;
                    top: 0px;
                    bottom: 0px;
                    left: 28px;
                    content: "";
                }
            }

            .info-container {
                flex-grow: 1;
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .info-top {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .info-content {
                        font-size: 20px;
                        color: #333333;

                        i {
                            font-size: 14px;
                        }
                    }

                    .info-time {
                        font-size: 16px;
                        color: #999999;
                    }
                }

                .info-desc {
                    margin-top: 2px;
                    font-size: 16px;
                    color: #999999;
                }

                .copy-members {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-top: 20px;

                    .copy-members-name {
                        margin-right: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }
            }

            span {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 56px;
                height: 56px;
                border-radius: 56px;
                border: 1px solid #3b77e7;
                text-align: center;
                /*line-height: 24px;*/
                font-size: 16px;
                color: #ffffff;
                background-color: #3b77e7;
                position: relative;

                i {
                    position: absolute;
                    bottom: -5px;
                    right: -5px;
                    font-size: 18px;
                    width: 18px;
                    height: 18px;
                    overflow: hidden;

                    &.el-icon-remove {
                        color: #ee9900;
                    }

                    &.el-icon-success {
                        color: #13ce66;
                    }

                    &.el-icon-error {
                        color: #ff0000;
                    }
                }
            }
        }
    }

    .approval-list-tip {
        font-size: 18px;
        color: #333333;
        flex-shrink: 1;
        margin-top: 12px;
    }
}
</style>
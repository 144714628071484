<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：总览 > <b>专项结项</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div v-if="detail" class="approval-detail-info">
            <el-button v-if="detail.sign" class="printing" type="primary" @click="printDialogVisible = true">
                打印
            </el-button>
            <div class="approval-detail-container">
                <div class="name-container">
                    <span>{{ detail.true_name | sub_name }}</span>
                    <div class="state-container">
                        <div class="content">{{ detail.true_name
                            }}<i :class="{red:detail.shenpi_num>1}">(第{{ detail.shenpi_num }}次提交)</i></div>
                        <div class="state">{{ detail.state | state }}</div>
                    </div>
                </div>
                <!--          <div class="approval-detail-title">-->
                <!--            <span class="approval-detail-tip">审批编号：</span-->
                <!--            ><span class="approval-detail-content">{{ detail.shenpi_no }}</span>-->
                <!--          </div>-->
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">项目编号：</span>
                    <span class="approval-detail-content">{{ detail.project.pro_no.join(',') }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">所属学会：</span>
                    <span class="approval-detail-content">{{ detail.society }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{$getTitleByType('会议名称',detail.project.cid)}}：</span>
                    <span class="approval-detail-content">{{ detail.project.name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{$getTitleByType('会议日期',detail.project.cid)}}：</span>
                    <span class="approval-detail-content">{{ detail.time }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{$getTitleByType('大会主席',detail.project.cid)}}：</span>
                    <span class="approval-detail-content">{{ detail.project.chairman }}</span>
                </div>
                <project-info :cid="detail.project.cid" :info="detail.project_attach"></project-info>
                <!--                <div class="approval-detail-title"><span class="approval-detail-tip">付款项目：</span><span-->
                <!--                        class="approval-detail-content">{{detail.pay_item}}</span></div>-->
                <!--<div class="approval-detail-title"><span class="approval-detail-tip">付款金额：</span><span-->
                <!--class="approval-detail-content">{{detail.pay_amount}}万元</span></div>-->
                <!--<div class="approval-detail-title"><span class="approval-detail-tip">收款单位账户：</span><span-->
                <!--class="approval-detail-content">{{detail.payee}}</span></div>-->
                <!--<div class="approval-detail-title"><span class="approval-detail-tip">收款单位开户行：</span><span-->
                <!--class="approval-detail-content">{{detail.bank_name}}</span></div>-->
                <!--<div class="approval-detail-title"><span class="approval-detail-tip">收款单位账号：</span><span-->
                <!--class="approval-detail-content">{{detail.bank_no}}</span></div>-->
                <!--<div class="approval-detail-title"><span class="approval-detail-tip">费用明细单：</span><span-->
                <!--class="approval-detail-content">{{detail.detail}}</span></div>-->
                <!--<div class="approval-detail-title"><span class="approval-detail-tip">付款交接文件：</span><span-->
                <!--class="approval-detail-content">{{detail.fukuan_jiaojie | fukuan_jiaojie}}</span></div>-->
                <!--<div class="approval-detail-title"><span class="approval-detail-tip">结算交接文件：</span><span-->
                <!--class="approval-detail-content">{{detail.jiesuan_jiaojie | jiesuan_jiaojie}}</span></div>-->
                <div class="approval-detail-title">
                    <span class="approval-detail-tip red">备注：</span>
                    <span class="approval-detail-content red">{{ detail.remark }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">二维码：</span>
                    <div class="approval-detail-content">
                        <el-image :src="detail.qr_code" style="width: 150px; height: 150px"></el-image>
                    </div>
                </div>
            </div>
            <div v-if="detail.sign" class="approval-detail-container">
                <div class="approval-list-tip">签字信息</div>
                <div class="project-info">
                    <div class="settlement-tab">
                        <div class="detail-info">
                            <h3>会议结算单</h3>
                            <h4><span>一、收款明细</span>
                            </h4>
                            <p class="active">单位：万元 </p>
                        </div>
                        <el-table
                            :data="settlement1"
                            header-row-class-name="table-header"
                            style="width: 1000px">
                            <el-table-column
                                align="center"
                                label="序号"
                                type="index"
                                width="100"
                            >
                            </el-table-column>
                            <el-table-column
                                align="center"
                                label="赞助单位"
                                prop="num1"
                            >
                            </el-table-column>
                            <el-table-column
                                align="center"
                                label="赞助金额"
                                prop="num2"
                            >
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ scope.row.num2 | tofixed }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                label="到账金额"
                                prop="num3"
                            >
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ scope.row.num3 | tofixed }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                label="服务费+税金"
                                prop="num4"
                            >
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ scope.row.num4 | tofixed }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                label="可用专项基金"
                                prop="num5"
                            >
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ scope.row.num5 | tofixed }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                label="备注"
                                prop="amount_desc">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ scope.row.amount_desc || '--' }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                v-if="eid === 0"
                                align="center"
                                label="操作"
                                width="200px"
                            >
                                <template slot-scope="scope">
                                    <div class="table-operate">
                                        <el-button size="small" style="font-size: 14px;" type="text"
                                                   @click="editItem(scope.$index)">编辑
                                        </el-button>
                                        <el-button size="small" style="font-size: 14px; color: #e4393c;" type="text"
                                                   @click="closeItem(scope.$index,1)">删除
                                        </el-button>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-table
                            :data="total"
                            :show-header="false"
                            class="table-total"
                            style="width: 1000px;background: #f2f2f2;">
                            <el-table-column
                                align="center"
                                width="100"
                            >
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ scope.row.name }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ scope.row.num1 }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ scope.row.num2 | tofixed }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ scope.row.num3 | tofixed }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ scope.row.num4 | tofixed }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ scope.row.num5 | tofixed }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ scope.row.num0 }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                v-if="eid === 0"
                                label=" "
                                width="200px"
                            >
                            </el-table-column>
                        </el-table>

                        <div class="detail-info">
                            <h4>二、付款明细
                            </h4>
                            <p class="active">单位：万元 </p>
                        </div>
                        <el-table
                            :data="settlement2"
                            header-row-class-name="table-header"
                            style="width: 1000px">
                            <el-table-column
                                align="center"
                                label="序号"
                                type="index"
                                width="100"
                            >
                            </el-table-column>
                            <el-table-column
                                align="center"
                                label="付款项目"
                                prop="num1"
                            >
                            </el-table-column>
                            <el-table-column
                                align="center"
                                label="付款金额"
                                prop="num2"
                            >
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ scope.row.num2 | tofixed }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                label="收款单位"
                                prop="num3"
                            >
                            </el-table-column>
                            <el-table-column
                                align="center"
                                label="专项基金余额"
                                prop="num4"
                            >
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ scope.row.num4 | tofixed }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                v-if="eid === 0"
                                align="center"
                                label="操作"
                                width="200px"
                            >
                                <template slot-scope="scope">
                                    <div class="table-operate">
                                        <el-button size="small" style="font-size: 14px;" type="text"
                                                   @click="editItem2(scope.$index)">编辑
                                        </el-button>
                                        <el-button size="small" style="font-size: 14px; color: #e4393c;" type="text"
                                                   @click="closeItem(scope.$index,2)">删除
                                        </el-button>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-table
                            :data="total2"
                            :show-header="false"
                            class="table-total"
                            style="width: 1000px;background: #f2f2f2;">
                            <el-table-column
                                align="center"
                                prop="name"
                                width="100"

                            >
                            </el-table-column>
                            <el-table-column
                                align="center"
                                prop=""
                            >
                            </el-table-column>
                            <el-table-column
                                align="center">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ scope.row.num1 | tofixed }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ scope.row.num2 }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ scope.row.num3 | tofixed }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                v-if="eid === 0"
                                align="center"
                                label=" "
                                width="200px"
                            >
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">签名：</span>
                    <div class="approval-detail-content">
                        <el-image
                            :src="detail.sign"
                            style="width: 550px; height: auto"
                        ></el-image>
                    </div>
                </div>
            </div>
            <div class="approval-list-container">
                <div class="approval-list-tip">审批流程</div>

                <div class="approval-list">
                    <div class="name-container active">
            <span>
              {{
                    detail.true_name | sub_name
                }}
              <i class="el-icon-success"></i>
            </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">{{ detail.true_name }}</div>
                                <div class="info-time">{{ detail.add_time | formatTime }}</div>
                            </div>
                            <div class="info-desc">发起申请</div>
                        </div>
                    </div>
                    <div class="name-container active">
            <span>
              签字
              <i
                  :class="detail.sign ? 'el-icon-success' : 'el-icon-remove'"
              ></i>
            </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">
                                    确认人
                                </div>
                                <div v-if="detail.allSp[0].sp_time" class="info-time">
                                    {{ detail.allSp[0].sp_time | formatTime }}
                                </div>
                            </div>
                            <div class="info-desc">
                                {{
                                    detail.sign
                                        ? "已签字"
                                        : "签字中"
                                }}
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="detail.state != 0 && detail.sign"
                        class="name-container active"
                    >
            <span>
              {{
                    detail.true_name | sub_name
                }}
              <i
                  :class="
                  detail.jiesuan_jiaojie && detail.jiesuan_jiaojie.length > 0
                    ? 'el-icon-success'
                    : 'el-icon-remove'
                "
              ></i>
            </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">{{ detail.true_name }}</div>
                                <div v-if="detail.allSp[1].sp_time" class="info-time">
                                    {{ detail.allSp[1].sp_time | formatTime }}
                                </div>
                            </div>
                            <div v-if="detail.jiesuan_jiaojie && detail.jiesuan_jiaojie.length > 0" class="info-desc">
                                已提交结算单
                            </div>
                            <div v-else class="info-desc">
                                <div>待确认</div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="
              detail.state != 0 &&
              detail.state != 2 &&
              detail.jiesuan_jiaojie && detail.jiesuan_jiaojie.length > 0
            "
                    >
                        <div v-for="(item,index) in detail.shenpi"
                             :key="item.id">
                            <div
                                v-if="index === 0 ? true : detail.shenpi[index-1].state === 1"
                                class="name-container active"
                            >
              <span
              >{{
                      item.true_name | sub_name
                  }}
                <i
                    :class="
                    item.state === 0 || item.state === 3
                      ? 'el-icon-remove'
                      : item.state === 1
                      ? 'el-icon-success'
                      : 'el-icon-error'
                  "
                ></i>
              </span>
                                <div class="info-container">
                                    <div class="info-top">
                                        <div class="info-content">
                                            {{ item.true_name
                                            }}<i v-if="item.state != 0"
                                        >({{ item.state | sp_state }})</i
                                        >
                                        </div>
                                        <div v-if="item.sp_time" class="info-time">
                                            {{ item.sp_time | formatTime }}
                                        </div>
                                    </div>
                                    <div v-if="item.state === 0" class="info-desc">
                                        {{ iself(item) ? "我（" : "" }}{{ item.state | sp_state
                                        }}{{ iself(item) ? "）" : "" }}
                                    </div>
                                    <div v-if="item.state !== 0" class="info-desc">
                                        {{ item.sp_remark }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="approval-list-container list-bottom">
                <div class="approval-list-tip list-bottom">审批流程</div>
                <div class="approval-list">
                    <div
                        v-if="detail.shenpi[detail.shenpi.length -1].state === 1"
                        class="name-container active"
                    >
            <span>
              {{
                    detail.true_name | sub_name
                }}
              <i
                  :class="
                  detail.confirm == 1 ? 'el-icon-success' : 'el-icon-remove'
                "
              ></i>
            </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">
                                    {{ detail.true_name }}
                                </div>
                                <div v-if="detail.allSp[detail.allSp.length - 1].sp_time" class="info-time">
                                    {{ detail.allSp[detail.allSp.length - 1].sp_time | formatTime }}
                                </div>
                            </div>
                            <div class="info-desc">
                                {{ detail.confirm == 1 ? "确认知晓" : "确认中" }}
                            </div>
                        </div>
                    </div>

                    <div class="name-container">
                        <span class="iconfont icontongzhi"></span>
                        <div class="info-container">
                            <div class="info-content">抄送人</div>
                            <div class="info-desc">抄送{{ detail.copy.length }}人</div>
                            <div class="copy-members">
                                <div
                                    v-for="item in detail.copy"
                                    :key="item.id"
                                    class="copy-members-name"
                                >
                  <span>{{
                          item.true_name | sub_name
                      }}</span>
                                    <div>{{ item.true_name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="approval-btn">
                <el-button
                    v-if="member_type == 1 && (detail.state == 0)"
                    class="approval-btn-left"
                    @click="toRevoke()">
                    撤回
                </el-button>
                <router-link :to="'/home/otherFpr?id=' + pid"  v-if="member_type == 1 && detail.state == 3">
                    <el-button class="approval-btn-left">重新提交
                    </el-button>
                </router-link>
                <el-button
                    v-if="(member_type == 1 && detail.state == 2) ||
                         (member_type == 1 && detail.state == 1 && detail.confirm == 0 && detail.shenpi[0].state == 1) ||
                         (member_type == 0 && isApprovaler)" class="approval-btn-left"
                    @click="shenpi_rejectedVisible = true">驳回
                </el-button>
                <el-button
                    v-if="member_type == 1 && detail.state == 2"
                    :disabled="requestState"
                    :loading="requestState" class="approval-btn-right"
                    @click="subFile()">
                    提交
                </el-button>
                <el-button
                    v-if="member_type == 1 && detail.state == 1 && detail.confirm == 0 && detail.shenpi[0].state == 1"
                    :disabled="requestState" :loading="requestState"
                    class="approval-btn-right"
                    @click="setConfirm()">
                    确认
                </el-button>
                <el-button
                    v-if="member_type == 0 && isApprovaler"
                    class="approval-btn-left"
                    @click="showDialog(2)">拒绝
                </el-button>
                <el-button
                    v-if="member_type == 0 && isApprovaler"
                    class="approval-btn-right"
                    @click="showDialog(1)">通过
                </el-button>
            </div>
        </div>
        <div v-if="detail.history && detail.history.length > 0" class="history-container">
            <div class="history-title">历史记录</div>
            <div class="history-list">
                <router-link
                    v-for="(item, index) in detail.history"
                    :key="index"
                    :to="'/home/otherFprDetail?cctype=1&id=' + item.id + '&type=' + member_type"
                    class="history-item"
                >
                    <div class="item-content">{{ index + 1 }}.{{ item.desc }}</div>
                    <div class="item-time">{{ item.add_time }}</div>
                </router-link>
            </div>
        </div>
        <el-dialog
            :before-close="closeDialog"
            :visible.sync="editVisible"
            center
            width="30%"
        >
      <span slot="title" class="dialog-title">{{
              state === 1 ? "审批同意确认" : "审批拒绝原因"
          }}</span>
            <div class="dialog-container flex-center">
                <el-input
                    v-model="remark"
                    :placeholder="
            state === 1 ? '请输入审批同意意见' : '请输入审批拒绝原因'
          "
                    :rows="5"
                    resize="none"
                    type="textarea"
                ></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button :disabled="requestState" :loading="requestState" type="primary" @click="toApproval()">确定</el-button>
      </span>
        </el-dialog>
        <el-dialog :before-close="closeDialog" :visible.sync="shenpi_rejectedVisible"
                   center width="30%">
            <span slot="title" class="dialog-title">审批驳回</span>
            <div class="dialog-container flex-center">
                <el-input v-model="remark" placeholder="请输入审批驳回原因"
                          :rows="5" resize="none" type="textarea"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button :disabled="requestState" :loading="requestState" type="primary"
                           @click="shenpi_rejected()">确定</el-button>
              </span>
        </el-dialog>
        <el-tooltip placement="top" effect="light">
            <div slot="content">
                <div v-for="(item,index) in detail.shenpi_rejected" :key="index">
                    {{ item.true_name }}{{item.create_time | formatTime}}驳回了审批，驳回原因为：{{item.message}}<br/>
                </div>
            </div>
            <div class="record flex align-center justify-center" v-if="detail.shenpi_rejected && detail.shenpi_rejected.length">驳回<br>记录</div>
        </el-tooltip>
        <other-fpr-print :detail="detail" :total2="total2" :total="total" :settlement1="settlement1" :settlement2="settlement2" :dialog-visible="printDialogVisible" @watchState="watchState"></other-fpr-print>
    </div>
</template>

<script>
import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
import ElImage from "../../../node_modules/element-ui/packages/image/src/main.vue";
import config from "../../config/index";
import {getPathIndex, sub_name, ZHDate} from "../../tools";
import OtherFprPrint from "./print/otherFpr";
import ProjectInfo from "../components/ProjectInfo";

export default {
    components: {
        ProjectInfo,
        OtherFprPrint,
        ElImage,
        ElButton,
    },
    name: "fprDetail",
    data() {
        return {
            pid: "",
            member_type: -1,
            jiesuan_jiaojie: [],
            isApprovaler: false,
            editVisible: false,
            shenpi_rejectedVisible: false,
            requestState: false,
            state: -1,
            detail: null,
            remark: "",
            settlement1: [],
            total: [
                {
                    name: '合计',
                    num1: '--',
                    num2: 0.0000,
                    num3: 0.0000,
                    num4: 0.0000,
                    num5: 0.0000,
                }
            ],
            settlement2: [],
            total2: [
                {
                    name: '合计',
                    num1: 0.0000,
                    num2: '--',
                    num3: 0.0000
                }
            ],
            printDialogVisible: false
        };
    },
    created() {
        this.$emit("setIndex", [0, getPathIndex(this.$route.query.type)]);
        this.init();
    },
    methods: {
        watchState() {
            this.printDialogVisible = false
        },
        showDialog(type) {
            this.editVisible = true;
            this.state = type;
            this.remark = this.state === 1 ? "同意" : "";
        },
        closeDialog() {
            this.remark = ''
            this.shenpi_rejectedVisible = false;
            this.editVisible = false;
            this.state = -1;
        },
        toApproval() {
            if (!this.remark) {
                this.$message.error(
                    this.state === 1 ? "请输入审批同意意见" : "请输入审批拒绝原因"
                );
                return;
            }
            this.requestState = true
            this.$api.apiContent
                .shenpi({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 13, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项 13 特殊结项
                    id: this.pid,
                    state: this.state,
                    remark: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 13, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项 13 特殊结项
                            id: this.pid,
                            state: this.state,
                            remark: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.closeDialog();
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        toRevoke() {
            this.$api.apiContent
                .revoke({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 13, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项 13 特殊结项
                    id: this.pid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 13, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项 13 特殊结项
                            id: this.pid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.backPage();
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                });
        },
        shenpi_rejected() {
            if (!this.remark) return  this.$message.error("请输入驳回原因")
            this.requestState = true
            this.$api.apiContent
                .shenpi_rejected({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 13, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    state:2,
                    id: this.pid,
                    remark: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 13, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            state:2,
                            id: this.pid,
                            remark: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        setConfirm() {
            this.requestState = true
            this.$api.apiContent
                .setConfirmOtherFpr({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    type: this.$route.query.cctype ? this.$route.query.cctype : "",
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            type: this.$route.query.cctype ? this.$route.query.cctype : "",
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        subFile() {
            this.requestState = true
            this.$api.apiContent
                .setSettlementEnd({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    jiesuan_jiaojie: '1,2',
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            jiesuan_jiaojie: '1,2',
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        init() {
            this.detail = {}
            this.pid = this.$route.query.id;
            this.member_type = this.$route.query.type;
            this.$api.apiContent
                .getJiexiangOther({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    type: this.$route.query.cctype ? this.$route.query.cctype : "",
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            type: this.$route.query.cctype ? this.$route.query.cctype : "",
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.history.map((item) => {
                        item.desc = item.desc.replace(/<br>/g, " ");
                        item.add_time = this.$tools.ZHDate(
                            new Date(item.add_time * 1000),
                            1
                        );
                    });
                    res.data.allSp = res.data.shenpi
                    res.data.shenpi = res.data.shenpi.filter(item => {
                        return !item.is_user || item.is_user === 2
                    })
                    res.data.time =
                        this.$tools.ZHDate(new Date(res.data.project.start_time * 1000)) +
                        " 至 " +
                        this.$tools.ZHDate(new Date(res.data.project.end_time * 1000));
                    if (res.data.jiesuan_jiaojie) res.data.jiesuan_jiaojie.sort()
                    res.data.qr_code = config.urlImgStr + res.data.qr_code;
                    res.data.sign = res.data.sign ? config.urlImgStr + res.data.sign : "";
                    this.detail = res.data;
                    // this.detail.jiesuan_jiaojie = this.detail.jiesuan_jiaojie.split(',')
                    this.isApproval();
                    this.requestState = false
                })
                .catch(() => {
                    this.detail = {};
                });
            this.$api.apiContent.specialTableInfos({
                token: this.$store.state.user.token,
                timestamp: this.$tools.requestTime(),
                id: this.pid,
                sign: this.$tools.SHA256({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                }, this.$store.state.user.key)
            }).then(res => {
                if (res.data.collection_detailed) {
                    this.settlement1 = res.data.collection_detailed
                    this.calcTotal()
                }
                if (res.data.payment_detailed) {
                    this.settlement2 = res.data.payment_detailed
                    this.calcTotal2()
                }
            })
        },
        calcTotal() {
            this.total = [
                {
                    name: '合计',
                    num1: '--',
                    num2: 0,
                    num3: 0,
                    num4: 0,
                    num5: 0,
                }
            ]
            this.settlement1.map(item => {
                this.total[0].num2 += item.num2 * 1
                this.total[0].num3 += item.num3 * 1
                this.total[0].num4 += item.num4 * 1
                this.total[0].num5 += item.num5 * 1
            })
        },
        calcTotal2() {
            this.total2 = [
                {
                    name: '合计',
                    num1: 0.0000,
                    num2: '--',
                    num3: 0.0000
                }
            ]
            this.settlement2.map(item => {
                this.total2[0].num1 += item.num2 * 1
            })
            this.total2[0].num3 = this.total[0].num5 * 1 - this.total2[0].num1 * 1
        },
        iself: function (item) {
            return item.user_id === this.$store.state.user.mid;
        },
        isApproval: function () {
            this.isApprovaler = false;
            this.shenpi_rejectedVisible = false;
            this.editVisible = false;
            this.detail.shenpi.map((item, index) => {
                if (item.user_id === this.$store.state.user.mid && item.state === 0) {
                    if (index === 0) {
                        this.isApprovaler = this.detail.state === 4;
                    } else {
                        if (this.detail.shenpi[index - 1].state === 1) {
                            this.isApprovaler = true;
                        }
                    }
                }
            });
        },
        backPage() {
            this.$tools.goBack();
        },
    },
    filters: {
        tofixed(val) {
            return parseFloat((val * 1).toFixed(6))
        },
        fukuan_jiaojie(type) {
            let arr = ["支出凭证", "下游专票", "下游协议", "项目明细", "付款确认单"];
            let str = "";
            type.map((item) => {
                str = str + " " + arr[Number(item) - 1];
            });
            return str;
        },
        jiesuan_jiaojie(type) {
            let arr = ["会议结算单", "电子结算文件"];
            let str = "";
            type.map((item) => {
                str = str + " " + arr[Number(item) - 1];
            });
            return str;
        },
        state(type) {
            switch (Number(type)) {
                case 0:
                    return "等待签字确认";
                case 1:
                    return "等待发起人确认";
                case 2:
                    return "已签字，等待提交结算交接文件";
                case 3:
                    return "已驳回";
                case 4:
                    return "审批中";
                case 5:
                    return "已撤销";
                case 6:
                    return "已完成";
            }
        },
        sp_state(type) {
            switch (Number(type)) {
                case 0:
                    return "审批中";
                case 3:
                    return "未审批";
                case 1:
                    return "已通过";
                default:
                    return "已驳回";
            }
        },
        formatTime(time) {
            return ZHDate(new Date(time * 1000), 6)
        },
        sub_name(str) {
            return sub_name(str)
        },
    },
    computed: {
        isSend: function () {
            return this.detail.user_id === this.$store.state.user.mid;
        },
    },
    watch: {
        $route: "init",
    },
};
</script>

<style lang="scss" scoped>
.page-box {
    .dialog-title {
        color: #000;
        font-size: 24px;
    }

    .dialog-footer {
        .el-button {
            width: 235px;
        }
    }

    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .approval-detail-info {
        position: relative;
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        min-height: 600px;
        padding-bottom: 100px;

        .printing {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        .approval-btn {
            display: flex;
            justify-content: center;
            flex-direction: row;
            width: 50%;
            position: absolute;
            bottom: 60px;
            padding-left: 120px;

            .approval-btn-left {
                width: 180px;
                background: transparent;
                border: 1px solid #3b77e7;
                color: #3b77e7;
            }

            .approval-btn-right {
                width: 180px;
                margin-left: 20px;
                background: #3b77e7;
                color: #ffffff;
            }
        }

        .approval-list-container {
            display: flex;
            flex-direction: row;
            padding: 40px 80px 0;

            &.list-bottom {
                padding: 0 80px;
            }

            .approval-list {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                margin-left: 12px;

                .name-container {
                    position: relative;
                    flex-grow: 1;
                    display: flex;
                    padding-bottom: 50px;
                    flex-direction: row;

                    &.active {
                        &:before {
                            position: absolute;
                            background: #d3d4d6;
                            width: 1px;
                            top: 0px;
                            bottom: 0px;
                            left: 28px;
                            content: "";
                        }
                    }

                    .info-container {
                        flex-grow: 1;
                        margin-left: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .info-top {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .info-content {
                                font-size: 20px;
                                color: #333333;

                                i {
                                    font-size: 14px;
                                }
                            }

                            .info-time {
                                font-size: 16px;
                                color: #999999;
                            }
                        }

                        .info-desc {
                            margin-top: 2px;
                            font-size: 16px;
                            color: #999999;
                        }

                        .copy-members {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            margin-top: 20px;

                            .copy-members-name {
                                margin-right: 10px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            }
                        }
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 56px;
                        height: 56px;
                        border-radius: 56px;
                        border: 1px solid #3b77e7;
                        text-align: center;
                        /*line-height: 24px;*/
                        font-size: 16px;
                        color: #ffffff;
                        background-color: #3b77e7;
                        position: relative;

                        i {
                            position: absolute;
                            bottom: -5px;
                            right: -5px;
                            font-size: 18px;
                            width: 18px;
                            height: 18px;
                            overflow: hidden;

                            &.el-icon-remove {
                                color: #ee9900;
                            }

                            &.el-icon-success {
                                color: #13ce66;
                            }

                            &.el-icon-error {
                                color: #ff0000;
                            }
                        }
                    }
                }
            }

            .approval-list-tip {
                font-size: 18px;
                color: #333333;
                flex-shrink: 1;
                margin-top: 12px;

                &.list-bottom {
                    color: #ffffff;
                }
            }
        }

        .approval-detail-container {
            padding: 40px;
            border-bottom: 1px solid #f0f2f5;

            .project-info {
                width: 100%;
                background-color: #ffffff;
                border-radius: 6px;
                overflow: hidden;
                min-height: 750px;

                .project-condition {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 24px 28px 16px;
                    border-bottom: 2px solid #f5f5f5;

                    .title-tab {
                        .active {
                            background: #3b77e7;
                            color: #fff;
                        }

                        .settlement {
                            margin-left: 10px;
                        }
                    }

                    .el-select {
                        margin-right: 20px;
                    }

                    .el-input {
                        margin: 0 20px;
                        width: 268px;
                    }
                }

                .settlement-tab {
                    padding: 30px;
                    width: 100%;

                    .detail-info {
                        width: 100%;

                        h3 {
                            text-align: center;
                            font-size: 24px;
                            line-height: 36px;
                        }

                        h4 {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 20px;
                            font-size: 18px;
                            margin-bottom: 15px;
                        }

                        p {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 16px;
                            line-height: 24px;
                            margin-bottom: 6px;

                            &.active {
                                justify-content: flex-start;
                            }
                        }
                    }
                }

                .save-btn {
                    display: block;
                    width: 120px;
                    margin: 30px auto;
                }
            }

            .approval-list-tip {
                margin-left: 40px;
                font-size: 18px;
                color: #333333;
                flex-shrink: 1;
            }

            .approval-detail-title {
                margin-top: 16px;
                display: flex;
                flex-direction: row;
                font-size: 18px;
                color: #999999;
                width: 100%;

                .approval-detail-tip {
                    flex: 1;
                    flex-shrink: 0;
                    text-align: right;

                    &.red {
                        color: red;
                    }
                }

                .approval-detail-content {
                    flex: 7;
                    flex-shrink: 1;
                    color: #333333;

                    &.red {
                        color: red;
                    }
                }
            }

            .name-container {
                display: flex;
                flex-direction: row;
                padding-left: 40px;

                .state-container {
                    margin-left: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .content {
                        font-size: 22px;
                        color: #333333;

                        .red {
                            color: red;
                        }
                    }

                    .state {
                        font-size: 16px;
                        margin-top: 6px;
                        color: #13ce66;
                    }
                }

                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 65px;
                    height: 65px;
                    border-radius: 65px;
                    text-align: center;
                    cursor: pointer;
                    /*line-height: 24px;*/
                    font-size: 20px;
                    color: #fff;
                    background-color: #3b77e7;
                }
            }
        }
    }
}
</style>
